import React, { useState } from "react";
import GFA from "./gfa";
import StationNav from "./stationNav";
import Metars from "./metars";
import Tafs from "./tafs";
import Notams from "./notams";
import Timestamp from "./timestamp";
import {
  NOTAM,
  NOTAMType,
  NOTAM_TYPE_AERODROME,
  NOTAM_TYPE_AREA,
  NOTAM_TYPE_FIR,
  NOTAM_TYPE_NATIONAL,
  NOTAM_TYPE_OTHER,
  Station,
  ValidICAOCode,
  isAirport,
  isCanadianAirport,
} from "@weatheredstrip/shared";
import Radar from "./radar";
import { useAirportsContext } from "../../context/airports";
import { ThunkStatus } from "../../store/constants";

interface PropsType {
  data: Station[];
  status: ThunkStatus;
  timestamp: Date | null;
}

const Content = (props: PropsType) => {
  const [notamType, setNotamType] = useState<NOTAMType>(NOTAM_TYPE_AERODROME);
  const { selected } = useAirportsContext();

  const { data, status } = props;

  const errorExists = () => {
    if (status === ThunkStatus.Failed) {
      return true;
    }

    if (selected && data[selected.icao_code]) {
      return data[selected.icao_code].ERROR ? true : false;
    } else {
      return false;
    }
  };

  if (selected && [ThunkStatus.Idle, ThunkStatus.Failed].includes(status)) {
    let selectedData: NOTAM[] | null = null;
    let lengths: Record<NOTAMType, number | null> = {
      [NOTAM_TYPE_AERODROME]: null,
      [NOTAM_TYPE_AREA]: null,
      [NOTAM_TYPE_FIR]: null,
      [NOTAM_TYPE_OTHER]: null,
      [NOTAM_TYPE_NATIONAL]: null,
    };

    const numberOfAirports = data.filter((station) =>
      isAirport(station)
    ).length;

    if (numberOfAirports === 0) {
      return (
        <div className="content">
          <div className="user-content">
            <div className="content-header">
              <div className="station-name">No valid airport code found.</div>
            </div>
            <div className="selected-content">
              <div id="notams">
                <div id="notam-header">
                  <div className="subtitle">
                    The provided airport codes where not found.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }

    const stationData = data.find(
      (station) =>
        isAirport(station) &&
        selected !== undefined &&
        station.icao_code === selected?.icao_code
    );

    if (!stationData) {
      return (
        <div className="content">
          <div className="user-content">
            <div className="content-header">
              <div className="station-name">
                Something went wrong fetching this station.
              </div>
            </div>
            <div className="selected-content">
              <div id="notams">
                <div id="notam-header">
                  <div className="subtitle">Please try again.</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }

    const gpsNotams = data.filter(
      (station) => station.codes.indexOf("KGPS" as ValidICAOCode) >= 0
    )[0].notam_EN;

    if (stationData.notam_EN) {
      const stationAerodrome = stationData.notam_EN.filter(
        (notam) => notam.type === "aerodrome"
      );

      let stationFIRNotams: NOTAM[] | null = [];

      if (isAirport(stationData)) {
        stationFIRNotams = isCanadianAirport(stationData)
          ? stationData.notam_EN.filter((notam) => notam.type === "FIR")
          : data.filter(
              (firStation) => firStation.codes[0] === stationData.FIR
            )[0].notam_EN;

        if (isCanadianAirport(stationData) && stationFIRNotams?.length === 0) {
          const potentialNOTAMs = data.filter(
            (firStation) => firStation.codes[0] === stationData.FIR
          )[0]?.notam_EN;

          if (potentialNOTAMs && potentialNOTAMs.length > 0) {
            stationFIRNotams = potentialNOTAMs;
          }
        }
      }

      const stationArea = stationData.notam_EN.filter(
        (notam) => notam.type === "area"
      );
      const stationNational = stationData.notam_EN.filter(
        (notam) => notam.type === "national"
      );
      lengths = {
        [NOTAM_TYPE_AERODROME]: stationAerodrome
          ? stationAerodrome.length
          : null,
        [NOTAM_TYPE_AREA]: stationArea ? stationArea.length : null,
        [NOTAM_TYPE_FIR]: stationFIRNotams ? stationFIRNotams.length : null,
        [NOTAM_TYPE_OTHER]: gpsNotams ? gpsNotams.length : null,
        [NOTAM_TYPE_NATIONAL]: stationNational ? stationNational.length : null,
      };

      switch (notamType) {
        case NOTAM_TYPE_OTHER:
          selectedData = gpsNotams;
          break;
        case NOTAM_TYPE_FIR:
          selectedData = stationFIRNotams;
          break;
        case NOTAM_TYPE_AERODROME:
          selectedData = stationAerodrome;
          break;
        case NOTAM_TYPE_AREA:
          selectedData = stationArea;
          break;
        case NOTAM_TYPE_NATIONAL:
          selectedData = stationNational;
          break;
        default:
          selectedData = gpsNotams;
      }
    }

    function renderStationName(): React.ReactNode {
      const maxNameLength = 35;
      if (selected === null) {
        return "Loading...";
      }
      const reducedStationName =
        selected.name.length > maxNameLength
          ? selected.name.substring(0, maxNameLength + 1) + "..."
          : selected.name;
      return `${selected[selected.prefered_code]} - ${reducedStationName}`;
    }

    return (
      <div className="content">
        {selected !== undefined && <StationNav data={data} />}
        <div className="user-content">
          {errorExists() ? (
            <>
              <div className="content-header">
                <div className="station-name">Error...</div>
              </div>
              <div className="selected-content">
                <div id="notams">
                  <div id="notam-header">
                    <div className="subtitle">
                      This ICAO identifier does not seem to be valid or is not
                      recognized by the server.
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="content-header">
                <div className="station-name">{renderStationName()}</div>
                {props.timestamp && <Timestamp dataTime={props.timestamp} />}
              </div>
              <div className="selected-content">
                <div className="metar-rvr">
                  {isCanadianAirport(stationData) &&
                    stationData.gfa?.length && (
                      <GFA data={stationData.gfa} selected={stationData} />
                    )}
                  <div className="col">
                    <div>
                      <div className="subtitle">METAR</div>
                      <Metars data={stationData.metar} />
                    </div>
                    <div>
                      <div className="subtitle">TAF</div>
                      <Tafs data={stationData.taf} />
                    </div>
                  </div>
                  {isCanadianAirport(stationData) &&
                    stationData.radar?.length && (
                      <Radar data={stationData.radar} />
                    )}
                </div>
                <Notams
                  onTypeSelection={(type: NOTAMType) => setNotamType(type)}
                  selectedType={notamType}
                  dataLength={lengths}
                  data={selectedData}
                />
              </div>
            </>
          )}
        </div>
      </div>
    );
  } else {
    return (
      <div className="content">
        <div className="user-content margin-6225">
          <div className="lds-ring">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      </div>
    );
  }
};

export default Content;
